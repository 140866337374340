.dashboard-page {
	overflow-x: hidden;
}

.analytic-widget {
	border: 1px solid transparentize($silver, 0.75);
	border-radius: 8px;
	padding: $sm-space $md-space;
	transition: filter ease .5s;
	flex: 1;
}

.loading-analytic {
	filter: blur(8px);
	pointer-events: none;
}

.loading-analytic.analytics-filters {
	filter: none;
}

.loading-analytic.loading-flex {
	display: flex;
	flex-direction: column;

	:not(:first-child) {
		flex: 1;
	}
}

.analytics-filters {
	.pinselectinput-label {
		margin: 0;
	}
	@include typo-default(1.2rem, $regular, $nickel);
}
