.day-view-planning {

	.day-title {
		@include typo-default();
		margin: auto;
		text-align: center;
	}

	.day-view-container {
		background-color: transparentize($silver, 0.75);
		border-radius: $md-radius;
		padding: 5px;
		margin: auto;

		.planning-event {
			background-color: $darkSoul-400;
			color: $snow;
			border-radius: $md-radius;

			.planning-content {
				padding: $xs-space;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				height: inherit;

				.handle {
					margin: 0 auto 0;
					text-align: center;
					height: 12px;

					&.top {
						margin: -$sm-space auto 0;
					}

					&.bottom {
						margin-top: auto;
					}
				}

				.text {
					margin-bottom: auto;
					p {
						&.pause {
							margin-top: $xs-space;
						}
						margin: 0;
						@include typo-default(1.2rem, $regular, $snow);
					}
				}

			}
		}
	}

	.day-view-footer-buttons {
		margin: $xs-space auto 0;
		display: flex;

		.pinbutton:first-child {
			margin-right: 2px;
			border-color: $silver;
			background-color: $silver;
		}

		.pinbutton {
			flex: 1;
			padding: 2px;
			min-width: 0;
			svg {
				margin: auto;
				display: block;
			}
		}
	}
}
