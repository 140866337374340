canvas.chartjs-render-monitor {
	@include typo-default(2.4rem, $bold);
}

.analytics-distribution-block {
	border: 1px solid $silver;
	border-radius: 18px;
	padding: $md-space;
	display: flex;
	flex-direction: column;

	.analytics-distribution-block-title {
		@include typo-default(2.4rem, $bold);

		.analytics-distribution-block-title-period {
			@include typo-default(1.2rem, $regular, $nickel);
			margin-left: $sm-space;
		}
	}

	.analytics-distribution-block-bar {
		display: flex;
		height: 4px;
		border-radius: 5px;
		background: $silver;
		margin: $sm-space 0;
		list-style: none;
		padding: 0;
		overflow: hidden;

		.analytics-distribution-block-bar-item {
			height: 100%;
			margin: 0;
		}
	}

	.analytics-distribution-block-list {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 0;
		margin: 0;

		.analytics-distribution-block-list-item {
			flex-grow: 0;
			margin-right: $md-space;

			.analytics-distribution-block-list-item-count {
				display: inline-block;
				@include typo-default(2.4rem, $bold);
			}

			.analytics-distribution-block-list-item-title {
				display: inline-block;
				@include typo-default(1.2rem, $regular, $nickel);
				white-space: nowrap;
				margin-left: $xs-space;
			}
		}

	}
}
