.scenarios-navigation-bar {

	.border-box {
		border-right: solid 1px $silver;
	}

	.section {
		@include typo-heading(2rem, $bold, $silver);
		text-align: end;
		display: block;
		transition: .1s ease-in-out;

		&.current {
			color: $activeButton;
		}
		&:hover {
			text-decoration: none;
			color: $darkSoul;
		}
	}
}
