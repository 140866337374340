.analytic-legend-container {
	position: relative;

	.analytic-legend-centered {
		@include typo-default(1.2rem, $bold, $nickel);
		position: absolute;
		top: 37%;
		height: 26%;
		width: 100%;
		text-align: center;
		color: $silver;
	}
}
