.conversations-in-progress-widget {
	border: 1px solid transparentize($silver, 0.75);
	border-radius: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: $xs-space;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	.text {
		@include typo-default(1.2rem);
		margin: 0 $xs-space;
	}

	.animated-dot {
		position: relative;
		width: 1.6rem;
		height: 1.6rem;
		background-color: $mellowYellow-100;
		border-radius: 50%;
		margin: .2rem .2rem .2rem 0;

		.dot-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.scaling-dot {
				border-radius: 50%;
				background-color: $mellowYellow-600;
				width: 100%;
				height: 100%;
			}
		}

		&.inactive .dot-container .scaling-dot {
			visibility: hidden;
		}
	}
}

.conversations-in-progress-widget .animated-dot .scaling-dot {
	animation: dot-scale-animation 1.9s infinite;
	animation-timing-function: ease-in;
	transform: scale(0);
}

@keyframes dot-scale-animation {
	0%		{ transform: scale(0);		opacity: 1;		}
	70%		{ transform: scale(1);		opacity: 0.3;	}
	100%	{ transform: scale(1);		opacity: 0;		}
}
