.app-header-wrapper {
	position: fixed;
	top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.admin-mode-banner {
	background: $darkSoul;
	padding: $xs-space $sm-space;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.title {
		@include typo-default($color: $snow);
	}

	.admin {
		@include typo-default(1.2rem, $regular, $coal);
	}
}

.admin-mode-placeholder {
	height: 37px;
	width: 100%;
}

.app-header {
	padding: 0 $md-space;
	@include shadow-light();
	height: 6rem;
	background: $snow;

	.app-header-logo {
		padding: 0;
		margin-right: 0;
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		flex: 1;
	}

	.app-header-agency-name {
		@include typo-default($color: $moon);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.app-header-menus-container {
		height: 100%;
		box-shadow: none;
		padding: 0;
		border-radius: 0;
		background: $snow;
		flex: 2;
	}

	.app-header-center-menu, .app-header-right-menu {
		flex: 1;
	}

	.app-header-center-menu {
		justify-content: space-around;

		.app-header-center-menu-items-wrapper {
			align-items: center;
			display: flex;

			a svg path {
				transition: .1s ease-in-out;
			}

			a:hover svg path {
				fill: $darkSoul;
			}
		}

		.app-header-menu-item {
			padding: $xs-space 0;
		}

		.assistant-relative-items {
			padding: .8rem 1.8rem;
			margin: 0 1.8rem;
			border-radius: 16px;
			background: transparentize($silver, 0.7);
			display: flex;
			flex-wrap: nowrap;
		}

		.assistant-relative-items a:not(:last-child) {
			margin-right: 1.6rem;
		}
	}

	.app-header-right-menu {
		align-items: center;
		justify-content: flex-end;
		height: 100%;
	}

	.app-header-right-menu .app-header-menu-item.disabled {
		@include typo-default(1.2rem, $regular, $silver);
	}
}

.placeholders {
	flex-shrink: 0;
}

.app-header-placeholder {
	height: 6rem;
}

@media (max-width: map-get($grid-breakpoints, md)) {
	.app-header-agency-name {
		display: none;
	}
}
