.base-configuration, .advanced-configuration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.increment-slider {
  width: 47rem;
  padding: $lg-space;
  padding-top: $md-space;
  border-radius: $lg-radius;
  border: 1px solid $silver;
}

.framed-group {
  padding: $md-space;
  border-radius: $lg-radius;
  border: 1px solid $silver;

  .inline-inputs {
    display: flex;
    align-items: baseline;

    .pintextinput {
      width: 6rem;
      margin-right: $xs-space;
      margin-bottom: 0 !important;

      .pintextinput-label {
        display: none;
      }
    }

    .pinselectinput {
      margin-bottom: 0 !important;
    }

    .pinselectinput.disabled, .pintextinput-input:disabled {
      pointer-events: none;
      color: $moon;
    }

    .pintoggle {
      margin-right: $sm-space;
      .pintoggle-option-label {
        display: none;
      }
      .switch {
        margin-bottom: 0;
      }
      .mb-md {
        margin-bottom: 0 !important;

        .mb-xs {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.calendar-section {

  .email-field {
    width: 30rem;
  }

  .calendar-source-selector {
    display: flex;

    .source-type-button {
      background: $snow;
      width: 26rem;
      padding: $md-space 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $silver;
      border-radius: $lg-radius;
      @include typo-default($size: 1.8rem);
      margin-right: $md-space;

      svg {
        margin-right: $sm-space;
      }

      &.selected {
        border-color: $minsk;
        font-weight: $semibold;
        color: $minsk;
      }
    }

  }
}

.week-calendar {
  margin: $md-space 0;
  display: flex;

  .day-column {
    flex: 1;
    text-align: center;
    margin-right: $sm-space;
    text-transform: capitalize;
    font-weight: $semibold;
    min-width: 9rem;
    max-width: 12rem;

    .pin-daily-periods-selector {
      max-width: 12rem;
      min-width: 9rem;
    }
  }
}

.add-period {
  .title {
    @include typo-default($size: 1.4rem, $weight: $semibold);
    margin-bottom: $sm-space;
  }

  .period-form {
    display: flex;

    .date-time-input {
      margin-right: $lg-space;

      .label {
        @include typo-default($size: 1.2rem, $color: $moon);
        margin-bottom: $xs-space;
      }

      .inputs {
        display: flex;
        align-items: flex-start;
        width: 21.5rem;

        .pin-date-picker {
          height: 3.4rem;
          margin-right: $sm-space;

          abbr {
            text-decoration: none;
          }
        }

        .pintextinput {
          flex: 1;
          margin-bottom: 0;

          .pintextinput-label {
            display: none;
          }

          .pintextinput-input {
            padding: $xs-space $xs-space;
            height: 34px;
            margin-top: 0;
            max-width: 6.7rem;
          }

          .pintextinput-input:disabled {
            color: $coal;
          }

          .pintextinput-error {
            white-space: nowrap;
            overflow: visible;
          }
        }
      }
    }
  }

  .options {
    display: flex;
    align-items: flex-end;

    .pincheckboxinput {
      width: 21.5rem;
      margin-right: $lg-space;
      margin-bottom: 0;
      @include typo-default($size: 1.4rem);

      .pincheckboxinput-option-input:checked + .pincheckboxinput-option-check {
        background-color: $minsk;
        border-color: $minsk;
      }
    }

    .spacer {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}

.no-period {
  margin-top: $md-space;
  padding: $sm-space;
  border-top: 1px solid $silver;
}

.unavailable-periods {
  margin-top: $md-space;

  .period {
    padding: $sm-space;
    border-top: 1px solid $silver;
    display: flex;

    .period-icon, .period-label, .frequency-label {
      margin-right: $sm-space;
    }

    .period-label strong {
      font-weight: $semibold;
    }

    .frequency-label {
      font-style: italic;
      color: $moon;
    }

    .remove-period-button {
      color: $failure;
    }
  }
}
