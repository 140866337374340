.scenario-card-component, .add-scenario-card-component {
	display: flex;

	.main-card {
		border-radius: $md-radius;
		@include shadow-medium();
		z-index: 10;
		padding: $md-space;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 41.5rem;

		.top-content {
			display: flex;
			justify-content: space-between;
			max-width: 39.5rem;
			overflow: hidden;

			.identification {
				display: flex;
				flex-direction: column;

				.title {
					@include typo-default(1.8rem, $semibold);
					margin-bottom: $xs-space;
				}
				.assistant-name {
					@include typo-default();
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.first-activity {
			@include typo-default(1.2rem);
			margin-bottom: $xs-space;
		}

		.tags-list {

			.pinpill:not(:last-child) {
				margin-right: $xs-space;
			}
		}
	}
}
