.simple-scenario-card-component {
	border-radius: $md-radius;
	@include shadow-medium();
	padding: $sm-space $md-space;
	display: flex;
	justify-content: space-between;

	.left-content {
		.identification {
			@include typo-default(1.8rem, $semibold);
		}
		.operate-by {
			@include typo-default();
			margin-left: $xs-space;
		}

		.first-activity {
			@include typo-default(1.2rem);
		}
	}
}
