.sequence-message {
	@include typo-default($color: $snow);
	padding: $md-space;
	border-radius: $lg-radius;
	background-color: $darkSoul-500;
	margin: $sm-space 0;

	.substitute {
		font-weight: bold;
	}
}

.sequence-message-delay, .not-responding {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	background-color: transparentize($silver, 0.75);
	border-radius: $md-radius;
	padding: $sm-space;

	.delay {
		margin-left: $sm-space;
	}
}

.not-responding {
	display: block;
	background-color: $grey-300;
	width: fit-content;
	margin-top: $sm-space;
}
