.popin-wrapper {
	position: absolute;
	@include shadow-heavy();
	@include typo-default();
	padding: $md-space;
	border-radius: $md-radius;
	background: $snow;
	color: $coal;
	z-index: 1000;
	top: 125%;

	&.right {
		left: 0;
	}

	&.left {
		right: 0;
	}

	&.open {
		opacity: 0;
		top: 125%;
		&.animated {
			opacity: 1;
			animation: show .2s ease-in-out forwards;
		}
	}

	&.close {
		opacity: 0;
		top: -10000px;

		&.animated {
			animation: hide .2s ease-in-out forwards;
		}
	}

	@keyframes show {
		0% { opacity: 0; top: 125%; }
		100% { opacity: 1; top: 125%; }
	}

	@keyframes hide {
		0% { opacity: 1; top: 125%; }
		99% { opacity: 0; top: 125%; }
		100% { opacity: 0; top: -10000px; }
	}
}
