.funnel {
	padding: $sm-space;

	.funnel-title {
		margin: 0 $sm-space;
		@include typo-default(2rem, $regular, $moon);
	}

	.funnel-steps {
		display: flex;
		margin-top: $sm-space;
		overflow: hidden;

		.funnel-step {
			flex: 1;
			margin: 0 $sm-space;
		}

		.funnel-step .funnel-step-main-value {
			@include typo-default(2.4rem, $bold);
		}

		.funnel-step .funnel-step-main-description {
			@include typo-default(1.2rem, $regular, $nickel);
			margin-left: $xs-space;
			overflow: hidden;
			white-space: nowrap;
		}

		.funnel-step .funnel-step-label {
			@include typo-default(1.2rem, $regular, $moon);
		}

		.funnel-step .funnel-step-prev-pct {
			@include typo-default(1.2rem, $regular);
			margin-right: $xs-space;
		}
	}

	.funnel-chart-wrapper {
		height: 9rem;
		border-radius: 0 0 $md-radius $md-radius;
		margin: -$sm-space;
		margin-top: -$md-space;
		overflow: hidden;
	}

	.funnel-chart {
		height: 8rem;
		// margin: -$md-space;
		// margin-bottom: -$sm-space;
		width: calc(100% + 50px);

		.ct-area {
			fill: $success;
			fill-opacity: 0.1;
			stroke: none;
		}

		.ct-line {
			fill: none;
			stroke: $success;
			stroke-width: 4px;
		}

		.ct-point {
			stroke: $success;
			stroke-linecap: round;
			stroke-width: 8px;
		}
	}
}
