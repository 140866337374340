.feature-selector .pinselectinput-current {
	border: none;
	border-radius: 0;
	border-bottom: solid 1px $nickel;
	@include typo-default(2rem);

	& > svg {
		margin-left: $sm-space;
	}
}

.pinselectinput-option svg {
	margin-bottom: 0.2rem !important;
	width: 1.6rem;
	height: 1.6rem;
}

.small > .feature-selector .pinselectinput-current {
	@include typo-default();
}
