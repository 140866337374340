.datepicker {
	@include typo-default();
	margin-bottom: $sm-space;

	.DayPicker-Months {
		flex-wrap: nowrap;
	}

	.DayPicker-Day--selected, .DayPicker-Day:hover {
		&:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
			background-color: $minsk-100 !important;
			color: $minsk;
		}
	}

	.DayPicker-Day {
		border-radius: 0 !important;
	}

	.DayPicker-Day--start:not(.DayPicker-Day--outside) {
		border-top-left-radius: 50% !important;
		border-bottom-left-radius: 50% !important;
		background-color: $minsk !important;
	}

	.DayPicker-Day--end:not(.DayPicker-Day--outside) {
		border-top-right-radius: 50% !important;
		border-bottom-right-radius: 50% !important;
		background-color: $minsk !important;
	}

}

.datepicker.no-to-date .DayPicker-Day--end:not(.DayPicker-Day--outside) {
	background-color: $minsk-100 !important;
	color: $minsk;
}
