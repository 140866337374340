.activation-plans-page-offer {
	background: $coal;
	border-radius: $md-radius;
	padding: $sm-space $md-space;

	.activation-plans-page-offer-main {
		@include typo-default(1.6rem, $bold, $warning);
	}

	.activation-plans-page-offer-secondary {
		@include typo-default(1.6rem, $regular, $snow);
	}
}
