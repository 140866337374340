.feature-management {
	display: flex;
	align-items: center;

	.title-bar {
		display: flex;
		align-items: center;
		margin-bottom: $xs-space;
		@include typo-default(2rem);
	}

	.title-bar .status {
		width: 1.4rem;
		margin-right: $xs-space;
		border-radius: 0.7rem;
		height: 1.4rem;
		background: $info;
	}

	.title-bar .status.actif {
		background: $success;
	}

	p {
		margin: 0;
	}
}
