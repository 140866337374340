.pinpo-action {
	font-weight: $bold;
}

.activation-card {
	padding: $md-space;
	margin: $md-space;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: $md-radius;
	@include shadow-medium();
}
