.breadcrumb {
	color: $nickel;
}

.hidden {
	pointer-events: none;
	opacity: 0;
}

.table-nc {
	color: $silver;
}

.not-clickable, .not-clickable * {
	cursor: default !important;
}

.blur {
	filter: blur(3px);
}

a.no-decorations:hover {
	text-decoration: inherit;
}

.max-width-60 {
	max-width: 60rem;
}
