.accordion-header {
	display: flex;
	justify-content: space-between;
	border-bottom: $silver 1px solid;
	cursor: pointer;

	.title {
		font-weight: bold;
	}

	.state-icon {
		transition: .3s ease-in-out;

		&.expanded {
			transform: rotate(180deg) !important;
		}
	}
}

.accordion-content {
	min-height: 0;
	overflow: hidden;
	transition: .3s ease-in-out;
}
