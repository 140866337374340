.usage-bar {
	max-width: 100%;

	.usage-bar-description {

		a.active, a.inactive {
			pointer-events: none;
			margin-left: $xs-space;
		}

		a.active {
			color: $info;
		}

		a.inactive {
			color: $success;
		}
	}
}
