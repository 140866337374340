.billing-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .billing-mode {
    background: transparentize(silver, 0.8);
    padding: $md-space;
    border-radius: $md-radius;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      display: flex;
      justify-content: center;

      .mode-title {
        margin-left: $sm-space;
        @include typo-default($weight: $semibold);
      }
    }
  }
}

.missing-infos {
  @include typo-default(1.4rem);
  font-style: italic;
}

.banking-infos-overlay {
  .label {
    @include typo-default($weight: $semibold);
  }
}
