.pintooltip {
	position: relative;

	.pintooltip-content {
		position: absolute;
		@include typo-default(1.2rem, $regular, $snow);
		white-space: normal;
		background: $darkSoul;
		width: 29rem;
		border-radius: $md-radius;
		padding: $sm-space $md-space;
		transition: opacity .2s ease;
		animation: pintooltip-hide .2s ease;
		animation-fill-mode: forwards;
		bottom: calc(100% + 1.6rem);
		left: -13.7rem;
		z-index: 5000;
	}

	.pintooltip-content.left {
		bottom: .8rem;
		transform: translateY(50%);
		left: -30.6rem;
	}
	.pintooltip-content.right {
		bottom: .8rem;
		transform: translateY(50%);
		left: auto;
		right: -30.6rem;
	}

	.pintooltip-content::after {
		content: "";

		position: absolute;
		display: block;
		width: 1.6rem;
		height: 1.6rem;
		background: $darkSoul;
		border-radius: 0 2px 0 0;
		left: calc(50% - .8rem);
		transform: rotate(135deg);
	}

	.pintooltip-content.left::after {
		transform: rotate(45deg);
		left: calc(100% - .8rem);
		top: calc(50% - .8rem);
	}
	.pintooltip-content.right::after {
		transform: rotate(225deg);
		left: -0.8rem;
		top: calc(50% - .8rem);
	}

	&:not(:hover) .pintooltip-content {
		visibility: hidden;
		transition: visibility 0.01s .2s;
	}

	&:hover {
		.pintooltip-content {
			animation: pintooltip-show .2s ease;
		}
	}

}

@keyframes pintooltip-show {
	0% { opacity: 0; z-index: 5000 }
	100% { opacity: 1; z-index: 5000; visibility: visible; }
}

@keyframes pintooltip-hide {
	0% { opacity: 1; z-index: 5000 }
	99% { opacity: 0; z-index: 5000 }
	100% { opacity: 0; z-index: -5000 }
}

@media (max-width: 400px) {
	.pintooltip .pintooltip-content {
		width: 20rem;
		left: -9.2rem;
	}
}

/* Special case: PINTable */

.pintable .pintable-row .pintable-row-wrapper {
	overflow: inherit;
}
.ck_flags,.ck_insights {
	overflow: visible !important;
}

.pintable-row:first-child {
	.pintooltip-content.left {
		bottom: 3.2rem;
		transform: translateY(100%);
	}
	.pintooltip-content.left::after {
		top: 1rem;
	}
}
.pintable-row:last-child {
	.pintooltip-content.left {
		bottom: -3.2rem;
		transform: translateY(0%);
	}
	.pintooltip-content.left::after {
		bottom: 1.6rem;
	}
}
