.pinnavigationsegment {
	position: relative;
	display: inline-block;
	border: 1px solid $silver;
	border-radius: 20px;
	padding: 2px;
	flex-shrink: 0;

	.pinsegment-component {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		border-radius: 20px;
		z-index: 100;

		.pinsegment-component-icon {
			margin: 0.7rem;
		}

		.pinsegment-component-label {
			margin: $xs-space $md-space;
		}
	}

	.pinnavigationsegment-background, .pinsegment-component.extra {
		background: $silver;
	}

	.pinnavigationsegment-background {
		display: inline-block;
		position: absolute;
		height: 30px;
		border-radius: 50px;
		z-index: 10;
		transition-timing-function: ease;
	}
}
