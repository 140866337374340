.pill {
	display: inline-block;
	padding: $xs-space $md-space;
	border-radius: 20px;
	color: $snow;

	.pill-content {
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}
}
