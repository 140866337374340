$facebookBlue: #1877f2;

.embedded-signup-page .container-pinpo {
  height: 100vh;

  .facebook-button {
    @extend .pinbutton;
    color: white;
    background-color: $facebookBlue;
    border-color: $facebookBlue;
    border-radius: 4px;
  }
  .facebook-button:hover {
    color: white;
    background-color: transparentize($facebookBlue, 0.1);
    border-color: transparentize($facebookBlue, 0.1);
  }
}
