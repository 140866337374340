.callout-component {
	display: flex;
	align-items: center;
	padding: $md-space;
	background-color: $minsk-50;
	border-radius: $sm-radius;

	svg {
		flex-shrink: 0;
	}
}
