.filter-pill {
	cursor: pointer;
	padding: $xxs-space $xs-space;
	@include typo-default(1.2rem);
	background: none;
	border: 1px solid $silver;
	border-radius: $md-radius;

	&.filled {
		background: $activeButton;
		border-color: $activeButton;
		@include typo-default(1.2rem, $regular, $snow);
	}
}
