.qualification-section {
	width: 100%;

	.form-fields-list {
		max-height: 40vh;
		overflow-x: scroll;
		width: 100%;

		.form-field {
			border-bottom: solid 1px $silver;
			display: flex;

			.type-infos {
				width: 12rem;
				margin-right: $sm-space;
			}

			.field-details {
				flex: 1;
				margin-right: $md-space;
			}

			.question {
				@include typo-default();
				margin-bottom: 0;
			}
			.description {
				@include typo-default(1.4rem);
				margin-bottom: 0;
			}

			.required {
				@include typo-default(1.4rem, $regular, $nickel);
				font-style: italic;
				text-align: center;
			}

			.type {
				text-align: center;
			}

			.identifier-container {
				display: flex;
				flex-direction: column;
				text-align: right;
				min-width: 6rem;

				.title {
					@include typo-default(1.4rem, $regular, $nickel);
				}
				.identifier {
					@include typo-default(1.4rem, $semibold);
					font-style: italic;
				}
				.field-number {
					padding-top: $sm-space;
					@include typo-default(1.2rem, $regular, $silver);
				}
			}
		}
	}
}
