.analytics-sub-card-component {
	display: flex;
	margin: $md-space 0;
	padding: $md-space;
	background-color: transparentize($silver, 0.8);
	border-radius: 0 $md-radius $md-radius 0;
	flex: 1;

	&.blurred {
		filter: blur(8px);
	}

	.analytics-container {
		flex: 1;
		display: flex;
		justify-content: space-between;
		margin: 0 $md-space;
	}

	.cta-menu {
		display: flex;
		flex-direction: column;
		margin-left: $xl-space;

		a svg path {
			transition: .1s ease-in-out;
		}

		a:not(:last-child) {
			margin-bottom: 0.6rem;
		}
		a:hover svg path {
			fill: $darkSoul;
		}
	}
}

@media (max-width: 136.5rem) {
	.analytics-sub-card-component {
		.analytics-container {
			margin-left: 0;
		}

		.cta-menu {
			margin-left: 0;
		}
	}
}
