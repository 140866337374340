.lead-quantity-simulation {

	.price-simulation {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	h1 {
		@include typo-default(3.4rem);
		text-align: center;
	}

	.engagement-length {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		@include typo-default($color: $nickel);
		margin-bottom: $sm-space;

		.left {
			text-align: right;
			flex: 1;
		}

		.center {
			display: flex;
			align-items: center;
			margin: 0 $sm-space;

			.pintoggle-option-label,
			.switch,
			.pintoggle div {
				margin: 0 !important;
				min-width: 0;
			}
		}

		.right {
			text-align: left;
			flex: 1;
		}
	}

	.facturation {
		@include typo-default($color: $nickel);
	}

	.unit-price {
		width: 100%;
		display: flex;
		justify-content: space-between;
		@include typo-default($color: $nickel);
		margin-bottom: $lg-space;

		.heavy {
			font-weight: $bold;
		}
	}

	.leads-quantity-form {
		display: flex;
		flex-direction: column;
		align-items: center;

		.selection-display {
			text-align: center;
			min-width: 25rem;
			border-radius: $md-radius;
			margin-bottom: $lg-space;

			.quantity {
				@include typo-default($weight: $bold);

				.inline-input {
					@include typo-default(2.4rem, $bold);
					max-width: 6.8rem;
					text-align: right;
					border: none;
					display: inline;
					padding: 0;
					margin: 0;
					-moz-appearance: textfield;

					&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					&:focus {
						outline: none;
					}

				}
			}
		}

		.quantity-slider {
			width: 100%;
			margin-bottom: $lg-space;

			.rc-slider-handle {
				width: 3rem;
				height: 3rem;
				background-color: $success;
				border: none;
				border-radius: 50%;
				margin-top: -1rem;
				margin-left: -1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;

				svg:first-child {
					transform: rotate(90deg);
					margin-right: $xs-space;
				}

				svg {
					transform: rotate(-90deg);
				}
			}
		}

	}

	.pricing-box {
		width: 100%;
		padding: $sm-space;
		text-align: center;
		border-radius: $md-radius;
		margin-bottom: $md-space;
		border: 2px solid $silver;
		@include typo-default(2rem);

		.total-price {
			@include typo-default(3.4rem);
		}
	}
}
