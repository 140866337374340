#form-file-upload {
    max-width: 100%;
    text-align: center;
    position: relative;
}
#input-file-upload {
    display: none;
}
#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    padding: 1rem;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
    width: 50%;
}


#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

table, .gray-background  {
    width: 90%;
    max-height: 200px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F0F0F2;
    border-radius: 1rem;
    overflow: scroll;
    overflow-x: hidden;
}

td {
    width: 20% ;
    padding: 1rem;
}

.error-detail {
    padding: 4rem;
}

.warning-info {
    display:inline-block;
}

.color-yellow-svg {
    filter: invert(63%) sepia(96%) saturate(316%) hue-rotate(1deg) brightness(104%) contrast(90%);
}

.link-back {
    float: right;
    color: #ffffff;
}
