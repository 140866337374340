.billing-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.info-card {
  border-radius: $md-radius;
  background: transparentize($silver, 0.8);
  padding: 2rem;

  .title {
    @include typo-default($size: 1.8rem, $weight: $bold);
    margin-bottom: $sm-space;
  }

  .info-line {
    display: flex;
    margin-top: $sm-space;

    svg {
      margin-right: $xs-space;
    }
  }
}
