.simple-add-scenario-card-component {
	@extend .simple-scenario-card-component;
	box-shadow: none;
	background-color: transparentize($silver, 0.7);
	align-items: center;

	.description {
		display: flex;

		.text {
			margin-left: $sm-space;
		}
	}
}
