.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color:  rgba(0,0,0,0.2);
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner{
    position: relative;
    padding: 60px 40px;
    width: 100%;
    max-width: 640px;
    max-height: 500px;
    background-color: #FFF;
    white-space: normal;
    overflow: scroll;
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}
