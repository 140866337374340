.export-button-wrapper, .import-button-wrapper {
  max-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  margin-bottom: auto;
  margin-left: 10rem;

  .export-limit-disclosure {
    @include typo-default(1.2rem);
    color: $failure;
    transition: opacity .1s ease-in-out;
    text-align: right;
  }

  a.pinbutton {
    min-width: unset;
    padding-right: $sm-space;
    padding-left: $sm-space;
  }

}

.inline {
  display: inline-block;
}

.with-border {
  border:solid;
  margin-right: 1rem;
  border-color:  #f2bd42 !important;
  border-radius: 1rem;
  padding: 0.5rem;
}
