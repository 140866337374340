.transcript-conversation {
	display: flex;
	flex-direction: column;
	padding: $md-space 0;

	.transcript-message-container {
		display: flex;
		margin-top: 2px;

		.message {
			padding: $xs-space $sm-space;
			border-radius: 0;
			color: $snow;
			max-width: 75%;
		}
	}

	.transcript-message-container:first-child,
	.transcript-message-container.left + .transcript-message-container.right,
	.transcript-message-container.right + .transcript-message-container.left {
		margin-top: $sm-space;

		.message {
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
		}
	}

	.transcript-message-container.left {
		justify-content: flex-start;

		.message {
			background: $info;
		}
	}

	.transcript-message-container.right {
		justify-content: flex-end;

		.message {
			background: $success;
		}
	}

	.transcript-message-container.left.last-of-serie .message {
		border-bottom-right-radius: 12px;
	}

	.transcript-message-container.right.last-of-serie .message {
		border-bottom-left-radius: 12px;
	}

}

.chat-bubble-container.sender-operator .chat-bubble:not(.shimmer-background) {
	background-color: $darkSoul-500;
}
