.scenario-activity-status {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	@include typo-default();

	.round-indicator {
		margin-left: $sm-space;
		display: inline-block;
		width: 1.4rem;
		height: 1.4rem;
		border-radius: 50%;
		background-color: $success;
	}
	.until-label {
		@include typo-default($color: $nickel);
		font-size: 1.4rem;
		white-space: nowrap;
	}
}
