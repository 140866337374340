.analytics-count-block {
	border: 1px solid $silver;
	border-radius: 18px;
	padding: $sm-space $md-space;
	display: flex;
	flex-direction: column;

	.analytics-count-block-values {
		display: flex;
		align-items: baseline;

		.analytics-count-block-values-count {
			@include typo-default(4.8rem, $bold);
			margin-right: $sm-space;
		}

		.analytics-count-block-values-evolution.up {
			color: $success;
		}

		.analytics-count-block-values-evolution.down {
			color: $failure;
		}
	}

	.analytics-count-block-unit {
		@include typo-default(2.4rem, $bold);
	}

	.analytics-count-block-period {
		@include typo-default(2.4rem, $bold);
	}
}
