.providers-distribution-widget {

	.pintable {
		margin-top: $sm-space;

		.pintable-header {
			border-bottom: none;
			color: $silver;
			padding: 0;

			.pintable-header-column {
				overflow: hidden;

				span {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.pintable-row, .pintable-row-wrapper {
			height: auto;
			min-height: 0;
			max-height: none;
		}

		.pintable-row {
			padding: $xs-space 0;
		}

		.pintable-row:first-child {
			padding-top: 0;
		}
	}
}
