/* Bootstrap styles and utils */
@import "./bootstrapSettings";
@import "node_modules/bootstrap/scss/bootstrap";

/* Pinpo UIKit and Web Framework styles and utils */
@import "~pinpo-ui-kit/lib/styles/PinpoUIKit";
@import "~pinpo-web-framework/lib/styles/PinpoWebFramework";

/* Customs styles and utils */
@import "./utils";

@import "./components/pages/authentication/LoginPage";
@import "./components/pages/authentication/SignUpPage";
@import "./components/pages/authentication/AdditionalInfoPage";
@import "./components/pages/authentication/PasswordChangePage";
@import "./components/pages/dashboard/DashboardPage";
@import "./components/pages/home/ScenarioCard/AnalyticsSubCard";
@import "./components/pages/home/ScenarioCard/ScenarioCard";
@import "./components/pages/home/AddScenarioCard";
@import "./components/pages/leads/ContactRequestsListingPage";
@import "./components/pages/leads/ContactRequestsImportPage";
@import "./components/pages/scenarios/AppointmentBookingSection";
@import "./components/pages/scenarios/ScenarioDetailsPage";
@import "./components/pages/scenarios/SimpleScenarioCard";
@import "./components/pages/scenarios/SimpleAddScenarioCard";
@import "./components/pages/scenarios/NavigationBar";
@import "./components/pages/scenarios/SectionHeader";
@import "./components/pages/scenarios/ScenariosSections";
@import "./components/pages/scenarios/SequencesSection";
@import "./components/pages/scenarios/SegmentationSection";
@import "./components/pages/scenarios/QualificationSection";
@import "./components/pages/onboard/OnBoardDemoAppointement";
@import "./components/pages/activation/ActivationPlansPage";
@import "./components/pages/activation/ActivationEmailPrivacy";
@import "./components/pages/settings/billing/mode/BillingModeEditPage";
@import "./components/pages/settings/billing/BillingModeSettings";
@import "./components/pages/settings/billing/BillingInfoSettings";
@import "./components/pages/settings/billing/BillingSettings";
@import "./components/pages/settings/billing/subscription/LeadsQuantitySimulation";
@import "./components/pages/settings/synchronisation/SourcesSettings";
@import "./components/pages/AppHeader";
@import "./components/pages/whatsapp/FacebookEmbeddedSignupPage";

@import "./components/parts/UserMenuItem";
@import "./components/parts/dashboard/analytics/UsageBar";
@import "./components/parts/dashboard/analytics/ContactRequestsFunnel";
@import "./components/parts/dashboard/analytics/ProvidersDistributionWidget";
@import "./components/parts/dashboard/analytics/CountBlock";
@import "./components/parts/dashboard/analytics/ConversationsInProgressWidget";
@import "./components/parts/dashboard/analytics/DistributionBlock";
@import "./components/parts/dashboard/contact-requests/ContactRequestsListingBody";
@import "./components/parts/dashboard/contact-requests/ContactRequestDetailsTranscript";
@import "./components/parts/settings/account/FeatureSuspensionManagement";
@import "./components/parts/settings/synchronization/SourcesSettings";
@import "./components/parts/settings/subscription/SubscriptionSettings";
@import "./components/parts/settings/account/feature-planning/DayPlanning";
@import "./components/parts/settings/setup/Setup";

@import "./components/shared/Anchor";
@import "./components/shared/OverlayCard";
@import "./components/shared/ui/Accordion";
@import "./components/shared/ui/Pill";
@import "./components/shared/ui/PopIn";
@import "./components/shared/ui/PopUp";
@import "./components/shared/ui/Tag";
@import "./components/shared/ui/Callout";
@import "./components/shared/ui/DropdownSwitcher";
@import "./components/shared/ui/DisplayConditions";
@import "./components/shared/ui/DisplayError";
@import "./components/shared/ui/PINNavigationSegment";
@import "./components/shared/analytics/AnalyticTitle";
@import "./components/shared/analytics/AnalyticTooltip";
@import "./components/shared/analytics/AnalyticValueLabeled";
@import "./components/shared/analytics/ProgressBar";
@import "./components/shared/analytics/LeadTemperatureChart";
@import "./components/shared/filters/FilterPill";
@import "./components/shared/filters/ScenarioSelector";
@import "./components/shared/filters/DateFilter";
@import "./components/shared/filters/DisplayFilter";
@import "./components/shared/filters/ContactRequestFiltersBar";
@import "./components/shared/scenarios/ActivityStatus";

@import "./components/forms/settings/SettingsAutoRefundForm";

.container {
	padding-bottom: 0;
}

.container-pinpo {
	@extend .container-fluid;
	padding: 0 8rem;
}

@media (max-width: 136.5rem) {
	.container-pinpo {
		padding: 0 4rem;
	}
}
