.dropdown-switcher * {
	display: inline-block;
}

.dropdown-switcher {
	position: relative;
	cursor: pointer;
}

.dropdown-switcher.closed .dropdown {
	opacity: 1;
	max-height: 1000px;
	animation: dropdown-switcher-hide .2s ease-in-out;
}

.dropdown-switcher.open .dropdown {
	opacity: 1;
	max-height: 1000px;
	animation: dropdown-switcher-show .2s ease-in-out;
}


.dropdown-switcher .dropdown {
	width: 16rem;
	position: absolute;
	margin-top: $sm-space;
	padding: $sm-space;
	background: $snow;
	border-radius: 8px;
	top: 100%;
	left: 0;
	@include shadow-light();
	border: 1px solid $silver;
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	cursor: default;

	i {
		display: initial;
	}

	.dropdown-item {
		display: flex;
		justify-content: space-between;
		margin-bottom: $sm-space;
		padding: 0;
		@include typo-default(2rem);
	}

	.dropdown-item:hover {
		background: none;
		cursor: pointer;
	}

	.dropdown-item:last-child {
		margin-bottom: 0;
	}
}

.dropdown-switcher.new .dropdown, .dropdown-switcher.closed .dropdown {
	opacity: 0;
	max-height: 0;
	padding: 0;
	margin-top: 0;
	border-width: 0;
}

@keyframes dropdown-switcher-show {
	0% { opacity: 0; max-height: 0; border-width: 0; }
	1% { opacity: 0; max-height: 1000px; margin-top: $sm-space; padding: $sm-space; border-width: 1px; }
	100% { opacity: 1; max-height: 1000px; margin-top: $sm-space; padding: $sm-space; border-width: 1px; }
}

@keyframes dropdown-switcher-hide {
	0% { opacity: 1; max-height: 1000px; margin-top: $sm-space; padding: $sm-space; border-width: 1px; }
	99% { opacity: 0; max-height: 1000px; margin-top: $sm-space; padding: $sm-space; border-width: 1px; }
	100% { opacity: 0; max-height: 0; padding: 0; margin-top: 0; border-width: 0; }
}

.dropdown-switcher.open .dropdown-switcher-state-indicator {
	transform: rotate(180deg);
}
