.contact-request-filters-bar {
	.count {
		@include typo-default(1.2rem, $regular, $moon);
	}
	.pintoggle.d-flex.justify-content-between {
		.mb-md {
			padding: 0 $md-space;
		}
	}
}
