.leads-listing-header-view {
	margin-top: 2px;
	text-align: center;
	border-radius: 4px;
	background: $silver;
	@include typo-default(1.2rem, $regular, $nickel);
}

.pintable-data-footer-view {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.pincheckboxinput .pincheckboxinput-option .pincheckboxinput-option-input:checked + .pincheckboxinput-option-check {
	background-color: $nightBlack;
	border: none;
}

.pintooltip {
	width: fit-content;
	.pinpill {
		cursor: pointer;
	}
}

.filter-display-list {
	cursor: pointer;
}

.pintable .pintable-row .pintable-row-column {
	@include typo-default(1.4rem, $regular, $coal);
}
