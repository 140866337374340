
.conditions-parts {
	padding: $md-space;
	overflow: hidden;
	transition: .5s ease-in-out;
	border-radius: $md-radius;
	text-align: center;

	.switch {
		margin-top: -1.15rem;
		margin-right: -.8rem;
	}

	&.child {
		border: 1px solid $moon;
	}

	.condition-item {
		text-align: center;

		.path, .value {
			font-weight: bold;
		}
	}

	.first-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.margin-box {
			width: 3.4rem;
		}
	}

	.conditions-separator {
		display: flex;
		align-items: center;
		margin: $md-space 0;

		.line {
			flex: 1;
			height: 1px;
			background-color: $moon;
		}

		.text {
			margin: 0 $md-space;
			text-transform: uppercase;
			font-size: 1.4rem;
			font-weight: bold;
			font-style: italic;
			color: $moon;
		}
	}
}

.conditions-parts.part-close {
	max-height: 6.3rem;

	.first-item {
		.switch {
			transform: rotate(180deg);
		}

		.condition-item, .conditions-parts {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1; /* number of lines to show */
			-webkit-box-orient: vertical;
		}
	}
}

.nothing-available {
	background-color: transparentize($silver, 0.8);
	border-radius: $md-radius;
	padding: $md-space;
}
