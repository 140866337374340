.email-sources-settings {

	.active {
		color: $success;
	}

	.failing {
		color: $failure;
	}

	.stopped {
		color: $warning;
	}
}

.source-type-item {
	display: block;
	width: 100%;
	cursor: pointer;
	@include typo-default(1.2rem, $regular, $nickel);
	background-color: transparentize($silver, 0.8);
	padding: $md-space;
	border-radius: $md-radius;
	text-decoration: none !important;
	color: $nightBlack !important;
	transition: .1s ease-in-out;

	&:hover, &.selected {
		@include shadow-medium();
		background-color: $snow;
	}

	&.soft-disabled {
		opacity: 0.5;
		box-shadow: none;
		cursor: auto;
		pointer-events: none;
		background-color: $silver;
	}

	.action-label {
		text-align: right;
		color: $success;
	}
}
