.user-menu-item {
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
}

.user-menu-item .user-menu-item-static {
	display: flex;
	align-items: center;
	margin: 0 $sm-space;

	.user-menu-item-initials {
		background: $silver;
		border-radius: 50%;
		width: 2.4rem;
		height: 2.4rem;
		display: none;
		justify-content: space-around;
		align-items: center;
		@include typo-default(1.2rem);
		line-height: 1.8rem;
	}
}

.user-menu-popin-wrapper {
	position: absolute;
	top: 200%;
	left: 4rem;
}

.user-menu-item-actions {
	list-style: none;
	padding: 0;
	margin: 0;

	.user-menu-item-action {
		@include typo-default(1.2rem);
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		margin: 0;

		svg:last-child {
			margin-left: $sm-space;
		}
	}
}

@media (min-width: 768px) {

	.user-menu-item .user-menu-item-static {
		margin: 0 $sm-space;

		.user-menu-item-initials {
			display: flex;
		}
	}

}
