.progress-bar {
	position: relative;
	background: $smoke;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
}

.progress-bar .progress-bar-progress {
	background: $darkSoul-800;

	-webkit-transition: width 1s ease-in-out;
	-moz-transition: width 1s ease-in-out;
	-o-transition: width 1s ease-in-out;
	transition: width 1s ease-in-out;
}


.progress-bar {
	&, .progress-bar-progress {
		height: 30px;
		border-radius: 15px;
	}
}

.progress-bar span {
	margin-top: 3px;
	display: block;

	&.dark {
		color: $coal;
	}
	&.light {
		color: white;
	}
	&.danger {
		color: $failure;
	}
}
